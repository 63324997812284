//
// Stepper Pills
//

// Base
.stepper.stepper-pills {
  --kt-stepper-pills-size: 40px;
  --kt-stepper-icon-border-radius: 9px;
  --kt-stepper-icon-check-size: 1rem;

  --kt-stepper-icon-bg-color: var(--kt-primary-light);
  --kt-stepper-icon-bg-color-current: var(--kt-primary);
  --kt-stepper-icon-bg-color-completed: var(--kt-primary-light);

  --kt-stepper-icon-border: 0;
  --kt-stepper-icon-border-current: 0;
  --kt-stepper-icon-border-completed: 0;

  --kt-stepper-icon-number-color: var(--kt-primary);
  --kt-stepper-icon-number-color-current: var(--kt-white);

  --kt-stepper-icon-check-color-completed: var(--kt-primary);

  --kt-stepper-label-title-opacity: 1;
  --kt-stepper-label-title-opacity-current: 1;
  --kt-stepper-label-title-opacity-completed: 1;

  --kt-stepper-label-title-color: var(--kt-gray-800);
  --kt-stepper-label-title-color-current: var(--kt-gray-600);
  --kt-stepper-label-title-color-completed: var(--kt-text-muted);

  --kt-stepper-label-desc-opacity: 1;
  --kt-stepper-label-desc-opacity-current: 1;
  --kt-stepper-label-desc-opacity-completed: 1;

  --kt-stepper-label-desc-color: var(--kt-text-muted);
  --kt-stepper-label-desc-color-current: var(--kt-text-400);
  --kt-stepper-label-desc-color-completed: var(--kt-gray-400);

  --kt-stepper-line-border: 1px dashed var(--kt-gray-300);

  // Nav
  .stepper-nav {
    display: flex;
  }

  // Item
  .stepper-item {
    display: flex;
    align-items: center;
    transition: $transition-link;

    // Icon
    .stepper-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: $transition-link;
      width: var(--kt-stepper-pills-size);
      height: var(--kt-stepper-pills-size);
      border-radius: var(--kt-stepper-icon-border-radius);
      background-color: var(--kt-stepper-icon-bg-color);
      border: var(--kt-stepper-icon-border);
      margin-right: 1.5rem;

      .stepper-check {
        display: none;
        font-size: var(--kt-stepper-icon-check-size);
      }

      .stepper-number {
        font-weight: $font-weight-bold;
        color: var(--kt-stepper-icon-number-color);
        font-size: 1.25rem;
      }
    }

    // Label
    .stepper-label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .stepper-title {
        color: var(--kt-stepper-label-title-color);
        opacity: var(--kt-stepper-label-title-opacity);
        font-weight: 600;
        font-size: 1.25rem;
        margin-bottom: 0.3rem;
      }

      .stepper-desc {
        opacity: var(--kt-stepper-label-desc-opacity);
        color: var(--kt-stepper-label-desc-color);
      }
    }

    // Current
    &.current {
      transition: $transition-link;

      .stepper-icon {
        transition: $transition-link;
        background-color: var(--kt-stepper-icon-bg-color-current);
        border: var(--kt-stepper-icon-border-current);

        .stepper-check {
          display: none;
        }

        .stepper-number {
          color: var(--kt-stepper-icon-number-color-current);
          font-size: 1.35rem;
        }
      }

      .stepper-label {
        .stepper-title {
          opacity: var(--kt-stepper-label-title-opacity-current);
          color: var(--kt-stepper-label-title-color-current);
        }

        .stepper-desc {
          opacity: var(--kt-stepper-label-desc-opacity-current);
          color: var(--kt-stepper-label-desc-color-current);
        }
      }
    }

    // Completed
    &.current.mark-completed:last-child,
    &.completed {
      .stepper-icon {
        transition: $transition-link;
        background-color: var(--kt-stepper-icon-bg-color-completed);
        border: var(--kt-stepper-icon-border-completed);

        .stepper-check {
          color: var(--kt-stepper-icon-check-color-completed);
          display: inline-block;
        }

        .stepper-number {
          display: none;
        }
      }

      .stepper-label {
        .stepper-title {
          opacity: var(--kt-stepper-label-title-opacity-completed);
          color: var(--kt-stepper-label-title-color-completed);
        }

        .stepper-desc {
          opacity: var(--kt-stepper-label-desc-opacity-completed);
          color: var(--kt-stepper-label-desc-color-completed);
        }
      }
    }
  }

  // Column
  &.stepper-column {
    // Nav
    .stepper-nav {
      flex-direction: column;
      align-items: start;
    }

    // Item
    .stepper-item {
      flex-direction: column;
      justify-content: start;
      align-items: stretch;
      padding: 0;
      margin: 0;
    }

    // Wrapper
    .stepper-wrapper {
      display: flex;
      align-items: center;
    }

    // Icon
    .stepper-icon {
      z-index: 1;
    }

    // Line
    .stepper-line {
      display: block;
      flex-grow: 1;
      margin-left: calc(var(--kt-stepper-pills-size) / 2);
      border-left: var(--kt-stepper-line-border);
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}
