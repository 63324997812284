.board-calendar-event {
  padding: 0 4px;
  width: 100%;
  position: relative;
  cursor: pointer;
  height: 100%;
}

.board-calendar-event__legend {
  border-radius: 4px;
  height: 10px;
  width: 40px;
  margin-bottom: 6px;
}

.board-calendar-event__description {
  white-space: pre-line;
  font-size: 11px;
}
