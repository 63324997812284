.text-accent {
  color: #a23cae !important;
}

.bg-accent {
  background-color: #a23cae !important;
}

.btn-accent {
  --bs-btn-color: #fff;
  --bs-btn-bg: #a23cae;
  --bs-btn-border-color: #a23cae;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #922f9d;
  --bs-btn-hover-border-color: #862b91;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #862b91;
  --bs-btn-active-border-color: #7b2786;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #a23cae;
  --bs-btn-disabled-border-color: #a23cae;
}
