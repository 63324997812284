//
// Loading
//

.feedback {
  display: none;
}

.feedback-popup {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: fixed;
  z-index: $feedback-popup-z-index;
  box-shadow: var(--kt-feedback-popup-box-shadow);
  background-color: var(--kt-feedback-popup-background-color);
  border-radius: $feedback-popup-border-radius;
  padding: $feedback-popup-padding;
}

// Placement
.feedback-top-center {
  display: flex;
  transition: top $feedback-popup-transition-speed ease;
  left: 50%;
  transform: translateX(-50%);
  @include border-top-start-radius(0);
  @include border-top-end-radius(0);

  &.feedback-shown {
    top: 0px;
    transition: top $feedback-popup-transition-speed ease;
  }
}
