.accordion-button {
  &:after {
    margin-left: var(--bs-accordion-btn-padding-x);
  }

  &.no-chevron::after {
    content: none;
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -2px !important;
}

.rmdp-container {
  width: 100%;
}

.tippy-box {
  max-width: 700px !important;
}

.tippy-arrow {
  color: #f5f8fa;
}

.time-slot {
  height: 170px;

  &:hover {
    height: 294px;
    margin-bottom: -124px;
    z-index: 10;
    position: relative;
    box-shadow: 0px 4px 10px 0px #e0e0e0;

    & .time-slot-scroll {
      max-height: 248px;
    }
  }
}

.time-slot-scroll {
  display: block;
  overflow: auto;
  max-height: 124px;
}

select option:disabled {
  color: #a8a8a8;
  font-weight: 300;
}

select option {
  font-weight: 500;
}

.svg-loader {
  width: 60px;
  height: 60px;
  animation: loader 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;

  & svg {
    width: 100%;
    height: 100%;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

input:disabled {
  opacity: 0.75 !important;
  pointer-events: none !important;
}

.board-card-private-type {
  position: relative;

  &:after {
    content: 'Pr';
    position: absolute;
    background-color: #009ef7;
    top: 0;
    right: 0;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    line-height: 1;
    padding: 2px 4px;
  }

  &:hover {
    & .board-card-hide {
      opacity: 1;
      pointer-events: fill;
    }
  }
}

.board-card-public-type {
  position: relative;

  &:after {
    content: 'Pub';
    position: absolute;
    background-color: #50cd89;
    top: 0;
    right: 0;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    line-height: 1;
    padding: 2px 4px;
  }

  &:hover {
    & .board-card-hide {
      opacity: 1;
      pointer-events: fill;
    }
  }
}

.board-card-hide {
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease;
  background-color: #ffffff;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.custom-calendar {
  //& .fc-event {
  //  display: flex !important;
  //  flex-direction: column !important;
  //  width: calc(100% - 4px) !important;
  //  border-radius: 4px !important;
  //  border: 1px solid #0095e8 !important;
  //  padding: 8px !important;
  //  box-sizing: border-box;
  //}
  //
  //& .fc-daygrid-event-dot {
  //  margin-right: auto !important;
  //  margin-left: 0 !important;
  //  width: 20px !important;
  //}
  //
  //& .fc-event-time {
  //  width: 100% !important;
  //  font-size: 13px !important;
  //  font-weight: 500 !important;
  //}
  //
  //& .fc-event-title {
  //  width: 100% !important;
  //  white-space: pre-wrap !important;
  //}

  &:fullscreen {
    background-color: #fff !important;
    padding: 16px !important;
    height: 100vh !important;
    overflow: auto !important;
  }
}

.fc .fc-timegrid-event .fc-event-main {
  overflow: hidden;
}

.fc .fc-daygrid-dot-event {
  background-color: transparent !important;
  color: transparent !important;
}

.pe-6 {
  padding-right: 3.5rem !important;
}
